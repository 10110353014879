<template>
    <section>
        <div class="d-flex mx-0 border-top" style="height:60px;">
            <div class="col pr-0">
                <p v-if="data.nombre != null" class="text-general py-3 px-4 f-16">
                    {{ data.nombre }}
                </p>
                <p v-else class="text-general py-3 px-4 f-16">
                    ------
                </p>
            </div>
        </div>
        <div class="d-flex mx-0 border-top" style="height:60px;">
            <div class="col pr-0">
                <p v-if="data.primer_acceso != null" class="text-general py-3 px-4 f-16">
                    {{ formatearFecha(data.primer_acceso) }}
                </p>
                <p v-else class="text-general py-3 px-4 f-16">
                    ------
                </p>
            </div>
        </div>
        <div class="d-flex mx-0 border-top" style="height:60px;">
            <div class="col pr-0">
                <p v-if="data.via_primer_acceso != null" class="text-general py-3 px-4 f-16">
                    <i class="text-general f-18" :class="data.via_primer_acceso == 1 ? 'icon-web' : data.via_primer_acceso == 2 ? 'icon-android' : data.via_primer_acceso == 3 ? 'icon-ios' : 'icon-manual'" />
                    {{ data.via_primer_acceso == 1 ? 'Web' : data.via_primer_acceso == 2 ? 'Android' : data.via_primer_acceso == 3 ? 'Ios' : 'Manual' }}
                </p>
                <p v-else class="text-general py-3 px-4 f-16">
                    ------
                </p>
            </div>
        </div>
        <div class="d-flex mx-0 border-top" style="height:60px;">
            <div class="col pr-0">
                <p v-if="data.primer_pedido != null" class="text-general py-3 px-4 f-16">
                    {{ formatearFecha(data.primer_pedido) }}
                </p>
                <p v-else class="text-general py-3 px-4 f-16">
                    ------
                </p>
            </div>
            <div v-if="data.primer_pedido" class="col text-general-red f-14 rompe-palabras px-0 py-3">
                {{ data.diferencia_primeros_registros }} Días post - registro
            </div>
        </div>
        <div class="d-flex mx-0 border-top" style="height:60px;">
            <div class="col pr-0">
                <p v-if="data.via_primer_pedido != null" class="text-general py-3 px-4 f-16">
                    <i class="text-general f-18" :class="data.via_primer_pedido == 1 ? 'icon-web' : data.via_primer_pedido == 2 ? 'icon-android' : data.via_primer_pedido == 3 ? 'icon-ios' : 'icon-manual'" />
                    {{ data.via_primer_pedido == 1 ? 'Web' : data.via_primer_pedido == 2 ? 'Android' : data.via_primer_pedido == 3 ? 'Ios' : 'Manual' }}
                </p>
                <p v-else class="text-general py-3 px-4 f-16">
                    ------
                </p>
            </div>
        </div>
        <div class="d-flex mx-0 border-top" style="height:60px;">
            <div class="col pr-0">
                <p v-if="data.valor_primer_pedido != null" class="text-general py-3 px-4 f-16">
                    {{ separadorNumero(data.valor_primer_pedido) }}
                </p>
                <p v-else class="text-general py-3 px-4 f-16">
                    ------
                </p>
            </div>
        </div>
        <div class="d-flex mx-0 border-top" style="height:60px;">
            <div class="col pr-0">
                <p v-if="data.id_primer_pedido != null" class="text-general py-3 px-4 f-16">
                    {{ data.id_primer_pedido }}
                </p>
                <p v-else class="text-general py-3 px-4 f-16">
                    ------
                </p>
            </div>
        </div>
        <div class="d-flex mx-0 border-top" style="height:60px;">
            <div class="col pr-0">
                <p v-if="data.total_pedidos_fecha != null" class="text-general py-3 px-4 f-16">
                    {{ agregarSeparadoresNumero(data.total_pedidos_fecha) }}
                </p>
                <p v-else class="text-general py-3 px-4 f-16">
                    ------
                </p>
            </div>
        </div>
        <div class="d-flex mx-0 border-top" style="height:60px;">
            <div class="col pr-0">
                <p v-if="data.ventas != null" class="text-general py-3 px-4 f-16">
                    {{ separadorNumero(data.ventas_fechas) }}
                </p>
                <p v-else class="text-general py-3 px-4 f-16">
                    ------
                </p>
            </div>
        </div>
        <div class="d-flex mx-0 border-top" style="height:60px;">
            <div class="col pr-0">
                <p v-if="data.total_pedidos != null" class="text-general py-3 px-4 f-16">
                    {{ agregarSeparadoresNumero(data.total_pedidos) }}
                </p>
                <p v-else class="text-general py-3 px-4 f-16">
                    ------
                </p>
            </div>
        </div>
        <div class="d-flex mx-0 border-top" style="height:60px;">
            <div class="col pr-0">
                <p v-if="data.ventas != null" class="text-general py-3 px-4 f-16">
                    {{ separadorNumero(data.ventas) }}
                </p>
                <p v-else class="text-general py-3 px-4 f-16">
                    ------
                </p>
            </div>
        </div>
        <div class="d-flex mx-0 border-top" style="height:60px;">
            <div class="col pr-0">
                <p v-if="data.pedidos_web != null" class="text-general py-3 px-4 f-16">
                    {{ agregarSeparadoresNumero(data.pedidos_web) }}
                </p>
                <p v-else class="text-general py-3 px-4 f-16">
                    ------
                </p>
            </div>
        </div>
        <div class="d-flex mx-0 border-top" style="height:60px;">
            <div class="col pr-0">
                <p v-if="data.pedidos_android != null" class="text-general py-3 px-4 f-16">
                    {{ agregarSeparadoresNumero(data.pedidos_android) }}
                </p>
                <p v-else class="text-general py-3 px-4 f-16">
                    ------
                </p>
            </div>
        </div>
        <div class="d-flex mx-0 border-top" style="height:60px;">
            <div class="col pr-0">
                <p v-if="data.pedidos_ios != null" class="text-general py-3 px-4 f-16">
                    {{ agregarSeparadoresNumero(data.pedidos_ios) }}
                </p>
                <p v-else class="text-general py-3 px-4 f-16">
                    ------
                </p>
            </div>
        </div>
        <div class="d-flex mx-0 border-top" style="height:60px;">
            <div class="col pr-0">
                <p v-if="data.pedidos_manuales != null" class="text-general py-3 px-4 f-16">
                    {{ agregarSeparadoresNumero(data.pedidos_manuales) }}
                </p>
                <p v-else class="text-general py-3 px-4 f-16">
                    ------
                </p>
            </div>
        </div>
        <div class="d-flex mx-0 border-top" style="height:60px;">
            <div class="col pr-0">
                <p v-if="data.ultimo_acceso != null" class="text-general py-3 px-4 f-16">
                    {{ formatearFecha(data.ultimo_acceso) }}
                </p>
                <p v-else class="text-general py-3 px-4 f-16">
                    ------
                </p>
            </div>
        </div>
        <div class="d-flex mx-0 border-top" style="height:60px;">
            <div class="col pr-0">
                <p v-if="data.via_ultimo_acceso != null" class="text-general py-3 px-4 f-16">
                    <i class="text-general f-18" :class="data.via_ultimo_acceso == 1 ? 'icon-web' : data.via_ultimo_acceso == 2 ? 'icon-android' : data.via_ultimo_acceso == 3 ? 'icon-ios' : 'icon-manual'" />
                    {{ data.via_ultimo_acceso == 1 ? 'Web' : data.via_ultimo_acceso == 2 ? 'Android' : data.via_ultimo_acceso == 3 ? 'Ios' : 'Manual' }}
                </p>
                <p v-else class="text-general py-3 px-4 f-16">
                    ------
                </p>
            </div>
        </div>
        <div class="d-flex mx-0 border-top" style="height:60px;">
            <div class="col pr-0">
                <p v-if="data.ultimo_pedido != null" class="text-general py-3 px-4 f-16">
                    {{ formatearFecha(data.ultimo_pedido) }}
                </p>
                <p v-else class="text-general py-3 px-4 f-16">
                    ------
                </p>
            </div>
        </div>
        <div class="d-flex mx-0 border-top" style="height:60px;">
            <div class="col pr-0">
                <p v-if="data.via_ultimo_pedido != null" class="text-general py-3 px-4 f-16">
                    <i class="text-general f-18" :class="data.via_ultimo_pedido == 1 ? 'icon-web' : data.via_ultimo_pedido == 2 ? 'icon-android' : data.via_ultimo_pedido == 3 ? 'icon-ios' : 'icon-manual'" />
                    {{ data.via_ultimo_pedido == 1 ? 'Web' : data.via_ultimo_pedido == 2 ? 'Android' : data.via_ultimo_pedido == 3 ? 'Ios' : 'Manual' }}
                </p>
                <p v-else class="text-general py-3 px-4 f-16">
                    ------
                </p>
            </div>
        </div>
        <div class="d-flex mx-0 border-top" style="height:60px;">
            <div class="col pr-0">
                <p v-if="data.valor_ultimo_pedido != null" class="text-general py-3 px-4 f-16">
                    {{ separadorNumero(data.valor_ultimo_pedido) }}
                </p>
                <p v-else class="text-general py-3 px-4 f-16">
                    ------
                </p>
            </div>
        </div>
        <div class="d-flex mx-0 border-top" style="height:60px;">
            <div class="col pr-0">
                <p v-if="data.id_ultimo_pedido != null" class="text-general py-3 px-4 f-16">
                    {{ data.id_ultimo_pedido }}
                </p>
                <p v-else class="text-general py-3 px-4 f-16">
                    ------
                </p>
            </div>
        </div>
        <div class="row mx-0 border-top">
            <div v-if="data.pedidos6Meses" class="col-12">
                <echart :options="ult6Meses" />
                <div v-if="_.sumBy(ult6Meses.dataset.source,'pedidos') == 0 && _.sumBy(ult6Meses.dataset.source,'ventas') == 0" class="position-absolute h-100 w-100" style="top:0px;left:0px;opacity:0.8;filter:blur(6px);background-color:#ffffff;z-index:1;" />
                <div v-if="_.sumBy(ult6Meses.dataset.source,'pedidos') == 0 && _.sumBy(ult6Meses.dataset.source,'ventas') == 0" class="img-sindatos text-center">
                    <img class="obj-cover" src="/img/otros/no_datos.svg" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            default: () => {}
        },
    },
    data(){
        let self = this
        return {
            ult6Meses: {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    },
                    backgroundColor: '#F5F5F5',
                    borderColor: '#F5F5F5',
                    formatter: function(obj){
                        // var value = obj.value;
                        return '<div style="border-bottom: 1px solid rgba(0,0,0,.3); font-size: 18px;padding-bottom: 7px;margin-bottom: 7px">'
                        + `<p class="text-general2 text-center" >${obj[0].data.mes}</p>`
                        + '</div>'
                        + '<div class="" style="min-width:150px" >'
                        +  `<i class="icon-blank f-18" style="color:${obj[0].color}" /><span class="text-general2 f-14">${self.agregarSeparadoresNumero(obj[0].data.pedidos)} ${obj[0].seriesName} </span>` + '<br>'
                        +  `<i class="icon-brightness f-18" style="color:${obj[1].color}" /><span class="text-general2 f-14">${self.agregarSeparadoresNumero(obj[1].data.ventas)} ${obj[1].seriesName} </span>` + '<br>'
                        + '</div>'
                    }
                },
                legend: {
                    data: ['Clientes que ingresaron', 'Interacciones totales']
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: true,
                        show:false
                    },
                ],
                yAxis: [
                    {
                        type: 'value',
                        scale: true,
                        // name: 'Valor',
                    },
                    {
                        type: 'value',
                        scale: true,
                        show: false
                        // name: 'Pedidos',
                    }
                ],
                dataset:{
                    dimensions:['mes', 'pedidos','ventas'],
                    source: []
                },
                series: [
                    {
                        name: 'No. pedidos',
                        type: 'bar',
                        barMaxWidth: 30,
                        // data: [120, 132, 101, 134, 90, 230, 210],
                        itemStyle: {
                            normal: {
                                color: '#DBDBDB',
                            },
                        },
                    },
                    {
                        name: 'Dinero',
                        // data: [220, 453, 301, 354, 290, 330, 320],
                        symbol: 'circle',
                        yAxisIndex: 1,
                        symbolSize: 10,
                        type: 'line',
                        itemStyle: {
                            normal: {
                                color: "#000000",
                            },
                        },
                    },
                ]
            },
        }
    },
    watch: {
        'data'(val){
            this.ult6Meses.dataset.source = val.pedidos6Meses
        }
    }
}
</script>

<style lang="scss" scoped>
.img-sindatos{
        position: absolute;
        z-index: 3;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0%;
        left: 0px;
        width: 100%;
        height: 100%;
        img{
            // border-radius: 50%;
            /* max-height: 100%; */
            box-shadow: 0px 3px 6px #00000029;
            max-width: 50%;
            height: 50%;
        }
        .mensaje{
            color: #fff;
            text-shadow: 0 2px 3px #000000;
            position: absolute;
            top: 58%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
</style>
